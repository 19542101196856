* {@include box-sizing(border-box);}

html {
	font-family: $mainFont, sans-serif;
	font-size: 14px;
	font-weight: $normal;
	line-height: 1.3;
	-webkit-font-smoothing: antialiased;
	@include apply-to(min, $xs) {
		font-size: 16px;
	}
	@include apply-to(min, $md) {
		font-size: 18px;
	}
}
body {
	line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: $bold;
	line-height: 1;
	color: $headingColor;
}
h1, h2 {
	font-family: $headingFont, sans-serif;
	text-transform: uppercase;
}
h3, h4, h5, h6 {
	font-family: $mainFont, sans-serif;
}

strong {
	font-weight: $bold;
}

p {
	font-weight: $thin;
}

a {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
}