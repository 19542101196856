//Mixins
@mixin clearfix {
	&:before,
	&:after {
		content: '';
		display: table;
	} //before, after
	&:after {
		clear: both;
	} //after
} //clearfix

@mixin apply-to($op, $res) {
	@media (#{$op}-width: $res + px) {
		@content;
	}
} //apply to

@mixin backImage(
	$image,
	$bgRepeat: no-repeat,
	$bgPos: center center,
	$bgSize: cover
) {
	background: url($image) $bgRepeat $bgPos;
	-webkit-background-size: $bgSize;
	-moz-background-size: $bgSize;
	-o-background-size: $bgSize;
	background-size: $bgSize;
} //backImage


@mixin box-sizing( $type: border-box ) {
	-webkit-box-sizing: $type;
	-moz-box-sizing: $type;
	-o-box-sizing: $type;
	-ms-box-sizing: $type;
	box-sizing: $type;
} // box-sizing