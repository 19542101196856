.menu-controls-wr {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	.page-scrolled & {
		background: $menuBg;
		box-shadow: 0 0 1px 1px darken($menuBg, 10);
	}

}
.menu-controls {
	padding-top: 20px;
	padding-bottom: 20px;
	transition: all .4s ease;
	.page-scrolled & {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	div[class^="col"] {
		@include clearfix;
	}
}
.menu-wr {
	width: 100%;
	height: 0;
	position: fixed;
	top: 0;
	z-index: 998;
	background: rgba($menuBg, .8);
	opacity: .7;
	overflow: hidden;
	transition: .8s ease;
	.menu-opened & {
		opacity: 1;
		height: 100%;
	}
}
#menu {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	li {
		padding: 10px;
		text-align: center;
	}
	a {
		text-align: center;
		font-size: 1.4em;
		transition: .5s ease;
		color: $menuTextColor;
		&:link,
		&:visited,
		&:focus,
		&:active {
			color: $menuTextColor;
		}
		&.active,
		&:hover {
			color: darken($menuTextColor, 10);
		}
	}
}
@media screen and (max-width: 768px ) and (orientation: landscape) {
	#menu {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		align-items: center;
	}
}
#logo {
	width: 120px;
	float: left;
}
.languages {
	@include clearfix;
	float: right;
	margin-top: 9px;
	margin-right: 10px;
	li {
		float: left;
		margin: 0 10px;
		text-transform: uppercase;
	}
	a {color: $menuTextColor;}
}
#menu-trigger {
	position: relative;
	width: 28px;
	height: 24px;
	float: right;
	margin-top: 7px;
	.line {
		background-color: $menuTextColor;
		display: block;
		width: 28px;
		height: 4px;
		margin: 4px 0;
		border-radius: 2px;
		position: absolute;
		top: 0;
		left: 0;
		transition: .3s ease;
		&:nth-of-type(1) {
			top: 2px;
			margin-top: 0;}
		&:nth-of-type(2) {top: 6px;}
		&:nth-of-type(3) {
			top: 14px;
			margin-bottom: 0;}
		.menu-opened & {
			&:nth-of-type(1) {transform: translate(-1px, 8px) rotate(135deg);}
			&:nth-of-type(2) {transform-origin: 50% 50%; transform: scale(0);}
			&:nth-of-type(3) {transform: translate(-1px, -8px) rotate(-135deg);}
		}
	}
}