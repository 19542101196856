#header {
	@include apply-to(min, $sm) {
		background-color: black;
		background-image: url("../images/header/wow.svg"),
						url("../images/header/wow.jpg");
		background-repeat: no-repeat, no-repeat;
		background-position: center top, center top;
		-webkit-background-size: cover, cover;
		background-size: cover, cover;
		height: 100vh;
		min-height: 700px;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			margin-top: -1px;
			background-color: white;
		}
	}
	.container {
		padding-top: 0;
		padding-bottom: 0;
		height: 100%;
	}
	.row-top {
		padding-top: 78px;
		background-color: $menuBg;
		@include apply-to(min, $sm) {
			height: 50%;
			background-color: transparent;
			div[class^="col"] {
				height: 100%;
			}
			.content-top {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	.h-middle {
		line-height: 1;
		color: white;
		margin: .5em 0;
		text-align: left;
		@include apply-to(min,$sm) {
			margin-top: 0;
		}
	}
	.icons img {
		display: inline-block;
		width: 55px;
		height: auto;
		margin-right: 55px;
		margin-bottom: 20px;
		&:last-of-type {
			margin-right: 0;
		}
		@include apply-to(min, $sm) {
			margin-bottom: 0;
		}
	}
	.row-bottom {
		div[class^="col"] {
			padding-top: 30px;
			padding-bottom: 50px;
		}
		@include apply-to(min, $sm) {
			height: 50%;
			div[class^="col"] {
				height: 100%;
			}
		}
	}
	.block-plus {
		display: none;
		@include apply-to(min, $sm) {
			display: block;
			width: 100%;
			max-width: calc(100% - 30px);
			height: auto;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.block-plus-mobile {
		margin: 0 auto;
		max-width: 290px;
		@include apply-to(min, $sm) {
			display: none;
		}
	}
}
#about-us {
	img {
		margin: 0 auto;
	}
	.text {
		margin-bottom: 1.2rem;
		&:last-of-type {
			margin-bottom: 2.4rem;
		}
	}
	.title {
		margin-top: 1rem;
		font-weight: $bold;
		text-align: center;
	}
	.international-cooperation {
		margin: 2rem 0;
		@include apply-to(min, $sm) {
			margin: 0;
		}
	}
	.icons-clock {
		padding-bottom: 60 / 18 + rem;
	}
}
#our-services {
	.row-flex {
		@include clearfix;
	}
	%block {
		padding-bottom: 2rem;
		@include apply-to(min, $sm) {
			padding-left: 30px;
			padding-right: 30px;
			width: 49.5%;
			float: left;
		}
		@include apply-to(min, $lg) {
			width: 33%;
		}
	}
	.heading {
		padding-left: 110px;
		font-size: 26 / 18 + rem;
		font-weight: $bold;
		line-height: 24 / 18 + rem;
		margin-bottom: 1rem;
		height: 110px;
		background-position: -10px center;
		background-repeat: no-repeat;
	}
	.information-security {
		@extend %block;
		.heading {
			border-bottom: 6px solid #ffe400;
			background-image: url("../images/our-services/icon-information-security.svg");
		}
	}
	.it-infrastructure {
		@extend %block;
		.heading {
			border-bottom: 6px solid #ffb82e;
			background-image: url("../images/our-services/icon-it-infrastructure.svg");
		}
	}
	.it-consulting {
		@extend %block;
		.heading {
			border-bottom: 6px solid #f58039;
			background-image: url("../images/our-services/icon-it-consulting.svg");
		}
	}
	.custom-software-development {
		@extend %block;
		.heading {
			border-bottom: 6px solid #ec4833;
			background-image: url("../images/our-services/icon-custom-software-development.svg");
		}
	}
	.enterprise-information-management {
		@extend %block;
		.heading {
			border-bottom: 6px solid #ff3271;
			background-image: url("../images/our-services/icon-enterprise-information-managemnet.svg");
		}
	}
	.surveillance-systems {
		@extend %block;
		.heading {
			border-bottom: 6px solid #d15391;
			background-image: url("../images/our-services/icon-surveillance-systems.svg");
		}
	}
}
#customer-support {
	background: black url("../images/customer-support/background-customer-support.jpg") no-repeat center 15%;
	-webkit-background-size: cover;
	background-size: cover;
	.customer-support {
		background: url("../images/customer-support/icon-customer-support.svg") no-repeat left center;
		-webkit-background-size: 9rem auto;
		background-size: 9rem auto;
		padding-left: 10rem;
		min-height: 9rem;
	}
	h4, p {
		font-weight: $bold;
		color: white;
	}
	h4 {
		font-size: 26 / 18 + rem;
		margin-bottom: .5em;
	}
	p {
		line-height: 1.1rem;
	}
}
#our-team {
	.logos {
		@include clearfix;
		margin-top: 2rem;
		@include apply-to(min, $sm) {
			margin-top: 0;
		}
	}
	%block-logo {
		width: 50%;
		float: left;
		&:nth-of-type(even) {
			@include apply-to(min, $sm) {
				transform: translateY(-50%);
			}
		}
		&:nth-of-type(odd) {
			img {
				margin-left: auto;
				@include apply-to(min, $sm) {
					margin-left: 0;
				}
			}
		}
	}
	.oracle {
		@extend %block-logo;
	}
	.cisco {
		@extend %block-logo;
	}
	.microsoft {
		@extend %block-logo;
	}
	.redhat {
		@extend %block-logo;
	}
}
#advantages {
	background: black url("../images/advantages/background-advantages.jpg") no-repeat center 35%;
	-webkit-background-size: cover;
	background-size: cover;
	img {
		margin: 0 auto;
	}
	.title {
		color: white;
		font-size: 1rem;
		font-weight: $bold;
		text-transform: uppercase;
		text-align: center;
	}
	.certificated-workers {
		margin: 2rem 0 1rem 0;
		.title {
			margin-top: 1rem;
		}
		@include apply-to(min, $sm) {
			margin: 0;
			.title {margin: 0;}
		}
	}
}
#abilities {
	%icon-block {
		height: 130px;
		padding-top: 110px;
		background-repeat: no-repeat;
		@include apply-to(min, $xs) {
			padding: 0 0 0 115px;
		}
	}
	.text {
		text-align: center;
		padding-bottom: 1rem;
		@include apply-to(min, $xs) {
			text-align: left;
			padding-bottom: 0;
		}
	}
	.security-systems {
		@extend %icon-block;
		background-image: url("../images/abilities/abilities-1.svg");
		background-position: center 20px;
		@include apply-to(min, $xs) {
			background-position: 8px center;
		}
	}
	.multimedia-systems {
		@extend %icon-block;
		background-image: url("../images/abilities/abilities-2.svg");
		background-position: center 10px;
		@include apply-to(min, $xs) {
			background-position: 10px center;
		}
	}
	.electronic-document-systems {
		@extend %icon-block;
		background-image: url("../images/abilities/abilities-3.svg");
		background-position: center 10px;
		@include apply-to(min, $xs) {
			background-position: 35px center;
		}
	}
	.command-centres {
		@extend %icon-block;
		background-image: url("../images/abilities/abilities-4.svg");
		background-position: center 17px;
		@include apply-to(min, $xs) {
			background-position: left center;
		}
	}
	.data-centres {
		@extend %icon-block;
		background-image: url("../images/abilities/abilities-5.svg");
		background-position: center 5px;
		@include apply-to(min, $xs) {
			background-position: 16px center;
		}
	}
	//  Safari fix
	.col-xs-6 {
		width: 49%;
	}
	@include apply-to(min, $md) {
		.col-md-4 {
			width: 33%;
		}
		.col-md-3 {
			width: 24%;
		}
	}
	// /Safari fix end
}
#footer {
	background-color: $footerBg;
	min-height: 200px;
	.logo {
		width: 120px;
		height: auto;
		margin: 0 auto;
		@include apply-to(min, $sm) {
			margin: 0;
		}
	}
	.e-pass {
		margin: 5px auto;
		width: 50px;
		@include apply-to(min, $sm) {
			margin: 5px 0;
		}
	}
	.company-name {
		color: #d0d0d0;
		line-height: 30 / 18 + rem;
		text-transform: uppercase;
		font-weight: $normal;
		text-align: center;
		@include apply-to(min, $sm) {
			text-align: left;
		}
	}
	.contacts {
		text-align: center;
		color: #d0d0d0;
		font-size: .9rem;
		margin-top: 20px;
		@include apply-to(min, $sm) {
			text-align: right;
			margin-top: 0;
		}
		a {
			font-weight: $normal;
			color: #d0d0d0;
			font-size: .9rem;
			display: block;
		}
		.reg-num,
		.address {
			font-weight: $normal;
		}
	}
	.created-by {
		margin-top: 20px;
		text-align: center;
		color: #d0d0d0;
		font-size: 12px;
		font-weight: $normal;
		line-height: 24px;
		.fa {
			color: red;
		}
		a {
			text-decoration: underline;
			color: red;
		}
	}
}