//Fonts
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,300);

//Fonts
$headingFont: Quicksand;
$mainFont: 'Open Sans';
$thin: 300;
$normal: 400;
$bold: 700;

//Colors
$textColor: #2c2c2c;
$headingColor: #242424;
$menuBg: #242424;
$menuTextColor: #f7d700;
$footerBg: #2b2b2b;

//Resolution breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;