.container {
	padding-top: 50 / 18 + rem;
	padding-bottom: 50 / 18 + rem;
}
.h-middle {
	font-size: 48 / 18 + rem;
	text-align: center;
	margin: 0 0 1em;
}
.row-flex {
	display: flex;
	flex-wrap: wrap;
}
.row-relative {
	position: relative;
}
.vc-parent {
	display: table;
	width: 100%;
}
.vc-child {
	display: table-cell;
	vertical-align: middle;
}
.triangles-coloured {
	width: calc(100% - 30px);
	height: 1px;
	//border: none;
	//color: #eaeaea;
	background-color: #eaeaea;
	position: absolute;
	left: 15px;
	bottom: 15px;
	@include apply-to(min, $md) {
		width: 100%;
		left: 0;
	}
	&:before {
		content: '';
		width: 56px;
		height: 34px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: url("../images/icons/triangles-coloured.svg") no-repeat left center;
		display: inline-block;
	}
}
.triangles-gray {
	width: calc(100% - 30px);
	height: 1px;
	//border: none;
	//color: #eaeaea;
	background-color: #eaeaea;
	position: absolute;
	left: 15px;
	bottom: 0;
	@include apply-to(min, $md) {
		width: 100%;
		left: 0;
	}
	&:after {
		content: '';
		width: 35px;
		height: 21px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		background: url("../images/icons/triangles-gray.svg") no-repeat left center;
		display: inline-block;
	}
}